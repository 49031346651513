.navbar {
    background-color: #333;
    position: fixed;
    top: 0;
    right: 0; /* right side */
    z-index: 100; /* navbar above other content */
}

.navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.navbar li {
    float: left;
    position: relative;
}

.navbar li a {
    display: block; /* disable for small navbar */
    color: white;
    text-align: center;
    padding: 10px 16px; /* vertical / horizontal */
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
}


.navbar li:nth-child(1) a:hover {
    background-color: red;
    /*color: blue;*/
}
.navbar li:nth-child(2) a:hover {
    background-color: orange;
    /*color: indigo;*/
}
.navbar li:nth-child(3) a:hover {
    background-color: yellow;
    color: grey;
    /*color: violet;*/
}
.navbar li:nth-child(4) a:hover {
    background-color: green;
    /*color: magenta;*/
}
.navbar li:nth-child(5) a:hover {
    background-color: blue;
    /*color: red;*/
}
.navbar li:nth-child(6) a:hover {
    background-color: indigo;
    /*color: orange;*/
}
.navbar li:nth-child(7) a:hover {
    background-color: violet;
    /*color: yellow;*/
}


.navbar li.active a {
    background-color: indigo;
    color: white;
}