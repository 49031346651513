.input-gymbro-name {
    width: 200px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.startGymaButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.startGymaButton:hover {
    background-color: #0056b3;
}
.error-starting {
    display: none;
}

.input-password {
    width: 200px;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}