footer {
    color: #fff;
    margin-top: 50px;
    margin-bottom: 20px;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    align-items: center;
}

footer a {
    color: darkred;
    text-decoration: none;
    padding: 5px 10px;
}

footer a:hover {
    color: darkgreen;
}

footer p {
    font-size: 14px;
    margin: 0;
}