.decimal-clock-frame {
    width: 300px;
    height: 300px;
    border: 2px solid #000;
    background-color: gold;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
}
.decimal-clock {

}

.clock-text {
    font-size: 18px;
    font-weight: bold;
    color: darkblue;
    margin: 6px;
}
.clock-dtime {
    font-size: 52px;
    font-weight: bold;
    color: darkblue;
    margin: auto;
}
.clock-time {
    color: darkblue;
    font-size: 20px;
    margin: 10px;
}