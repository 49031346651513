.exercise-input-card {
    /*min-height: 10vh;*/
}

.input-fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input-fields input {
    flex: 1; /* Expand input fields to fill available space */
    margin-right: 10px; /* Add spacing between input fields */
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.exercise {

}

.plus-icon {
    width: 30px;
    height: 30px;
    background-color: #007bff;
    color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.plus-icon:hover {
    background-color: #0056b3;
}