main {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    max-width: 100%;
}
.gyma-section-active {

}
.gyma-section-server {

}
.gyma-section-history-local {

}