.poke-card {
    width: 300px;
    background-color: #48CAE4;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ensure content within the card does not overflow */
}

.poke-picture {
    width: 100%;
    height: 200px;
    margin-top: 10px;
}
@media (prefers-reduced-motion: no-preference) {
    .poke-picture {
        animation: spin infinite 10s linear;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.poke-picture img {
    width: 100%;
    height: auto;
    display: block;
    pointer-events: none;
}

.poke-title {
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #ccc;
    color: darkgreen;
    /*background-color: rgba(255, 255, 255, 0.8);*/
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5));
}
.poke-title h2 {
    background-color: black;
}
.poke-text {
    padding: 20px;
    text-align: justify;
}
