.gyma-card {
    border: 2px solid dodgerblue;
    min-width: 50vw;
    /*min-height: 30vh;*/
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: 0 5px 10px darkblue;
    background-color: aquamarine;

    @media (max-width: 1081px) {
        width: 80%;
    }
    @media (max-width: 1079px) {
        width: 100%;
    }
}
.gyma-card-header {
    font-weight: bold;
    color: black;
    background: cadetblue;
    text-align: left;
}


.exercise-table-field {
    width: 100%;
    margin-bottom: 15px;
}

.exercise-table-field table {
    /*border-collapse: collapse;*/

}

.exercise-table-field th, .exercise-table-field td {
    width: 100%;
    border: 1px solid #ddd; /* Add border to cells */
    padding: 8px; /* Add padding to cells */
    text-align: left; /* Align text to the left */
}

.exercise-table-field th {
    background-color: #f2f2f2; /* Add background color to header cells */
    color: #333; /* Change header text color */
}
.exercise-table-field tr {
    background-color: white;
    color: black;
}

.exercise-row.gains {
    background-color: #ffcccc; /* Light red for gains */
}

.exercise-row.cardio {
    background-color: #ccffcc; /* Light green for cardio */
}

.exercise-row.other {
    background-color: #cce6ff; /* Light blue for other */
}

.exercise-row.gains:hover {
    background-color: #ff9999; /* Slightly darker red for gains on hover */
}

.exercise-row.cardio:hover {
    background-color: #99ff99; /* Slightly darker green for cardio on hover */
}

.exercise-row.other:hover {
    background-color: #99ccff; /* Slightly darker blue for other on hover */
}

