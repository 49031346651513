.random-image-frame {
    width: 500px;
    height: 500px;
    background-color: lightgreen;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.random-image-frame img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    pointer-events: none;
}

@media (max-width: 1079px) {
    .random-image-frame {
        width: 300px;
        height: 300px;
    }
}
